import auditApi from "../../api/audit.api";
import filterDefault from "../../helpers/filter";

export const state = {
  audits: [],
  audit: {},
  total: 0,
  filter: {
    ...filterDefault,
  },
  types: [
    "AUTHENTICATION",
    "COURSE",
    "PROFILE",
    "NOTIFICATION",
    "AUDIT",
    "SURVEY",
  ],
};

export const getters = {
  audits(state) {
    return state.audits;
  },

  filter(state) {
    return state.filter;
  },

  statuses(state) {
    return state.statuses;
  },

  total(state) {
    return state.total;
  },
};

export const mutations = {
  SET_AUDITS(state, audits) {
    state.audits = audits;
  },

  ADD_AUDIT(state, audit) {
    state.audits.unshift(audit);
  },

  MERGE_AUDITS(state, audits) {
    state.audits = audits.concat(state.audits);
  },

  SET_FILTER(state, { key, value }) {
    state.filter[key] = value;
  },

  REMOVE_AUDIT(state, id) {
    state.audits.splice(
      state.audits.findIndex((audit) => audit.id === id),
      1
    );
  },

  SET_AUDIT(state, audit) {
    state.audit = audit;
  },

  CHANGE_AUDIT(state, { id, audit }) {
    state.audits[state.audits.findIndex((audit) => audit.id === id)] = audit;
  },

  SET_TOTAL(state, total) {
    state.total = total;
  },
};

export const actions = {
  async changeFilter({ dispatch, commit }, { key, value }) {
    commit("SET_FILTER", { key, value });

    dispatch("fetch");
  },

  async fetch({ commit, state }) {
    try {
      const { data } = await auditApi.fetch(state.filter);

      commit("SET_AUDITS", data.data);
      commit("SET_TOTAL", data.data.total);
    } catch (error) {
      console.log(error);
    }
  },
};
