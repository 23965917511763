export const menuItems = [
  {
    label: "Menu utama",
    isTitle: true,
    roles: ["ADMIN"],
  },
  // {
  //   label: "Dashboard",
  //   icon: "ri-dashboard-line",
  //   link: "/",
  //   roles: ["ADMIN"],
  //   subItems: [
  //     {
  //       label: "Overall Member",
  //       icon: "ri-dashboard-line",
  //       link: "/",
  //       roles: ["ADMIN"],
  //     },
  //     {
  //       label: "Transaksi",
  //       icon: "ri-dashboard-line",
  //       link: "/dashboard/order",
  //       roles: ["ADMIN"],
  //     },
  //     {
  //       label: "Enrollment",
  //       icon: "ri-dashboard-line",
  //       link: "/dashboard/enrollment",
  //       roles: ["ADMIN"],
  //     },
  //   ],
  // },
  {
    label: "Banner",
    icon: "ri-chat-1-line",
    link: "/banner",
    roles: ["ADMIN"],
  },
  // {
  //   label: "Survey",
  //   icon: "ri-chat-1-line",
  //   link: "/survey",
  //   roles: ["ADMIN"],
  // },
  // {
  //   label: "Giveaway",
  //   icon: "ri-chat-1-line",
  //   link: "/giveaway",
  //   roles: ["ADMIN"],
  // },
  {
    label: "Topik",
    icon: "ri-artboard-line",
    link: "/category",
    roles: ["ADMIN"],
  },

  {
    label: "Blast Pemberitahuan",
    icon: "ri-artboard-line",
    link: "/notification-blast",
    roles: ["ADMIN"],
  },

  // {
  //   label: "Pertanyaan Onboarding",
  //   icon: "ri-artboard-line",
  //   link: "/onboarding",
  //   roles: ["ADMIN"],
  // },

  // {
  //   label: "Pesanan",
  //   icon: "ri-artboard-line",
  //   link: "/order",
  //   roles: ["ADMIN"],
  //   subItems: [
  //     {
  //       label: "Pesanan",
  //       icon: "ri-artboard-line",
  //       link: "/order",
  //       roles: ["ADMIN"],
  //     },
  //     {
  //       label: "Bukti Pembayaran",
  //       icon: "ri-artboard-line",
  //       link: "/order/proof-payment",
  //       roles: ["ADMIN"],
  //     },

  //     {
  //       label: "Voucher",
  //       icon: " ri-user-3-line",
  //       link: "/voucher",
  //       roles: ["ADMIN"],
  //     },
  //   ],
  // },

  {
    label: "Kelas",
    icon: "ri-artboard-line",
    link: "/course",
    roles: ["ADMIN", "FASILITATOR", "MENTOR"],
    subItems: [
      {
        label: "Kelas",
        icon: "ri-artboard-line",
        link: "/course",
        roles: ["ADMIN", "FASILITATOR", "MENTOR"],
      },
      {
        label: "Modul",
        icon: "ri-book-2-line",
        link: "/module",
        roles: ["ADMIN", "FASILITATOR", "MENTOR"],
      },
      {
        label: "Sub Modul",
        icon: "ri-book-2-line",
        link: "/submodule",
        roles: ["ADMIN", "FASILITATOR", "MENTOR"],
      },
      {
        label: "Batch Kelas",
        icon: "ri-book-2-line",
        link: "/batch",
        roles: ["ADMIN"],
      },

      {
        label: "Aktivasi Kelas",
        icon: "ri-questionnaire-line",
        link: "/activation-class",
        roles: ["ADMIN"],
      },
      {
        label: "Opsi Rating",
        icon: "ri-questionnaire-line",
        link: "/rating-option",
        roles: ["ADMIN"],
      },
      {
        label: "Kuis",
        icon: " ri-user-3-line",
        link: "/exam",
        roles: ["ADMIN"],
      },

      {
        label: "Tanya Jawab Kelas",
        icon: " ri-user-3-line",
        link: "/course/question",
        roles: ["ADMIN", "MENTOR"],
      },

      {
        label: "Bank QNA",
        icon: "ri-questionnaire-line",
        link: "/bank-qna",
        roles: ["ADMIN", "FASILITATOR", "MENTOR"],
      },
    ],
  },

  {
    label: "Sertifikat",
    icon: "ri-artboard-line",
    link: "/certification-master",
    roles: ["ADMIN"],
    subItems: [
      {
        label: "Master Sertifikat",
        icon: "ri-artboard-line",
        link: "/certification-master",
        roles: ["ADMIN"],
      },
      {
        label: "Master Sertifikat Kelas",
        icon: "ri-artboard-line",
        link: "/certification",
        roles: ["ADMIN"],
      },
      {
        label: "Sertifikat Member",
        icon: "ri-artboard-line",
        link: "/user-course-certificate",
        roles: ["ADMIN"],
      },
      {
        label: "Sertifikat Event",
        icon: "ri-artboard-line",
        link: "/certification-event",
        roles: ["ADMIN"],
      },
    ],
  },

  // {
  //   label: "Partnership",
  //   icon: " ri-user-3-line",
  //   roles: ["ADMIN"],
  //   subItems: [
  //     {
  //       label: "Iklan",
  //       icon: " ri-user-3-line",
  //       link: "/partnership/advertisement",
  //       roles: ["ADMIN"],
  //     },
  //     {
  //       label: "Program",
  //       icon: " ri-user-3-line",
  //       link: "/partnership",
  //       roles: ["ADMIN"],
  //     },
  //     {
  //       label: "Event",
  //       icon: " ri-user-3-line",
  //       link: "/partnership/item",
  //       roles: ["ADMIN"],
  //     },
  //     {
  //       label: "Event Kategori",
  //       icon: " ri-user-3-line",
  //       link: "/partnership/item-category",
  //       roles: ["ADMIN"],
  //     },
  //     {
  //       label: "Produk",
  //       icon: " ri-user-3-line",
  //       roles: ["ADMIN"],
  //       subItems: [
  //         {
  //           label: "Produk",
  //           icon: " ri-user-3-line",
  //           link: "/partnership/partner/product",
  //           roles: ["ADMIN"],
  //         },
  //         {
  //           label: "Kategori",
  //           icon: " ri-user-3-line",
  //           link: "/partnership/partner/product/category",
  //           roles: ["ADMIN"],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Partner",
  //       icon: " ri-user-3-line",
  //       roles: ["ADMIN"],
  //       subItems: [
  //         {
  //           label: "Partner",
  //           icon: " ri-user-3-line",
  //           link: "/partnership/partner",
  //           roles: ["ADMIN"],
  //         },
  //         {
  //           label: "Kategori",
  //           icon: " ri-user-3-line",
  //           link: "/partnership/partner-category",
  //           roles: ["ADMIN"],
  //         },
  //         {
  //           label: "Tipe",
  //           icon: " ri-user-3-line",
  //           link: "/partnership/partner-type",
  //           roles: ["ADMIN"],
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   label: "Forum",
  //   icon: " ri-user-3-line",
  //   link: "/forum",
  //   roles: ["ADMIN", "MENTOR"],
  //   subItems: [
  //     {
  //       label: "Forum",
  //       icon: " ri-user-3-line",
  //       link: "/forum",
  //       roles: ["ADMIN", "MENTOR"],
  //     },
  //     {
  //       label: "Group",
  //       icon: "ri-user-3-line",
  //       link: "/forum-group",
  //       roles: ["ADMIN"],
  //     },
  //     {
  //       label: "Partner",
  //       icon: " ri-user-3-line",
  //       link: "/forum-partner",
  //       roles: ["ADMIN"],
  //     },
  //     {
  //       label: "Regional",
  //       icon: " ri-user-3-line",
  //       link: "/forum/regional",
  //       roles: ["ADMIN"],
  //     },
  //     {
  //       label: "Laporan",
  //       icon: " ri-user-3-line",
  //       link: "/forum/report",
  //       roles: ["ADMIN", "MENTOR"],
  //     },
  //   ],
  // },

  // {
  //   label: "Klinik",
  //   icon: " ri-user-3-line",
  //   roles: ["ADMIN", "FASILITATOR"],
  //   subItems: [
  //     {
  //       label: "Dashboard",
  //       icon: "ri-shield-user-line",
  //       link: "/clinic/dashboard",
  //       roles: ["ADMIN", "FASILITATOR"],
  //     },
  //     {
  //       label: "Pertanyaan",
  //       icon: "ri-shield-user-line",
  //       link: "/clinic",
  //       roles: ["ADMIN", "FASILITATOR"],
  //     },
  //     {
  //       label: "Kategori",
  //       icon: "ri-shield-user-line",
  //       link: "/clinic-category",
  //       roles: ["ADMIN"],
  //     },
  //   ],
  // },

  // {
  //   label: "Whatsapp Bot",
  //   icon: " ri-user-3-line",
  //   roles: ["ADMIN", "FASILITATOR"],
  //   subItems: [
  //     {
  //       label: "Group",
  //       icon: "ri-shield-user-line",
  //       link: "/whatsapp-group",
  //       roles: ["ADMIN"],
  //       subItems: [
  //         {
  //           label: "Group",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group",
  //           roles: ["ADMIN"],
  //         },
  //         {
  //           label: "Onboarding Filter",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-onboarding-filter",
  //           roles: ["ADMIN"],
  //         },
  //         {
  //           label: "Invite Ke Grup",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-invite",
  //           roles: ["ADMIN"],
  //         },
  //         {
  //           label: "Invitation History",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-invitation-history",
  //           roles: ["ADMIN"],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Absen",
  //       icon: "ri-shield-user-line",
  //       roles: ["ADMIN"],
  //       subItems: [
  //         // {
  //         //   label: "Histori Absen",
  //         //   icon: "ri-shield-user-line",
  //         //   link: "/whatsapp-group-greeting-history",
  //         //   roles: ["ADMIN"],
  //         // },
  //         {
  //           label: "Template Absen",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-greeting-template",
  //           roles: ["ADMIN"],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Survey Keluar Grup",
  //       icon: "ri-shield-user-line",
  //       roles: ["ADMIN"],
  //       subItems: [
  //         {
  //           label: "Survey",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-leave-survey",
  //           roles: ["ADMIN"],
  //         },
  //         {
  //           label: "Pertanyaan",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-leave-survey-question",
  //           roles: ["ADMIN"],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Blaster",
  //       icon: "ri-shield-user-line",
  //       roles: ["ADMIN"],
  //       subItems: [
  //         {
  //           label: "Blaster",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-blaster",
  //           roles: ["ADMIN"],
  //         },
  //         {
  //           label: "Blaster Filter Template",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-blaster-filter-template",
  //           roles: ["ADMIN"],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Broadcast",
  //       icon: "ri-shield-user-line",
  //       link: "/whatsapp-broadcast",
  //       roles: ["ADMIN"],
  //     },
  //     {
  //       label: "Kulwa",
  //       icon: "ri-shield-user-line",
  //       roles: ["ADMIN"],
  //       subItems: [
  //         {
  //           label: "Kulwa",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-kulwa",
  //           roles: ["ADMIN"],
  //         },
  //         {
  //           label: "Template",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-kulwa-template",
  //           roles: ["ADMIN"],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Word Cloud",
  //       icon: "ri-shield-user-line",
  //       roles: ["ADMIN"],
  //       subItems: [
  //         {
  //           label: "Rangkuman",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-word-search-summary",
  //           roles: ["ADMIN"],
  //         },
  //         {
  //           label: "Data Kata",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-word-search-summary-word",
  //           roles: ["ADMIN"],
  //         },
  //         {
  //           label: "Kategori Kata",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-word-search-summary-word-category",
  //           roles: ["ADMIN"],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Import",
  //       icon: "ri-shield-user-line",
  //       roles: ["ADMIN"],
  //       subItems: [
  //         {
  //           label: "Import Data",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-import",
  //           roles: ["ADMIN"],
  //         },
  //         {
  //           label: "Import Data Pending",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-import-pending",
  //           roles: ["ADMIN"],
  //         },
  //         {
  //           label: "Import Data Kontak",
  //           icon: "ri-shield-user-line",
  //           link: "/whatsapp-group-import-contact",
  //           roles: ["ADMIN"],
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    label: "Master Member",
    icon: " ri-user-3-line",
    roles: ["ADMIN", "FASILITATOR"],
    subItems: [
      {
        label: "Master Admin",
        icon: "ri-shield-user-line",
        link: "/admin",
        roles: ["ADMIN"],
      },
      {
        label: "Member",
        icon: " ri-user-3-line",
        link: "/member",
        roles: ["ADMIN", "FASILITATOR"],
      },
      // {
      //   label: "Badge Member",
      //   icon: "ri-questionnaire-line",
      //   link: "/badge",
      //   roles: ["ADMIN"],
      // },
      {
        label: "Role",
        icon: " ri-user-3-line",
        link: "/role",
        roles: ["ADMIN"],
      },
      // {
      //   label: "Kategori",
      //   icon: " ri-user-3-line",
      //   link: "/user/category",
      //   roles: ["ADMIN", "FASILITATOR"],
      // },
      // {
      //   label: "Utm",
      //   icon: " ri-user-3-line",
      //   link: "/utm",
      //   roles: ["ADMIN"],
      // },
      {
        label: "Log Aktivitas",
        icon: "ri-chat-1-line",
        link: "/log",
        roles: ["ADMIN"],
      },
      {
        label: "Log Audit",
        icon: "ri-chat-1-line",
        link: "/log-audit",
        roles: ["ADMIN"],
      },
      // {
      //   label: "OTP Pendaftaran",
      //   icon: "ri-chat-1-line",
      //   link: "/user-otp",
      //   roles: ["ADMIN"],
      // },
    ],
  },

  {
    label: "Template Pemberitahuan",
    icon: "ri-gear-line",
    link: "/notification-template",
    roles: ["ADMIN"],
  },
  // {
  //   label: "Pengaturan",
  //   icon: "ri-gear-line",
  //   link: "/setting",
  //   roles: ["ADMIN"],
  // },

  // Partner
  {
    label: "Resume Aktivitas",
    icon: "ri-shield-user-line",
    link: "/whatsapp-group/resume-activity",
    roles: ["PARTNER"],
  },
  {
    label: "Template Absen",
    icon: "ri-shield-user-line",
    link: "/whatsapp-group-greeting-template",
    roles: ["PARTNER"],
  },
  {
    label: "Group",
    icon: "ri-shield-user-line",
    link: "/whatsapp-group",
    roles: ["PARTNER"],
    subItems: [
      {
        label: "Group",
        icon: "ri-shield-user-line",
        link: "/whatsapp-group",
        roles: ["PARTNER"],
      },
      {
        label: "Invite Ke Grup",
        icon: "ri-shield-user-line",
        link: "/whatsapp-group-invite",
        roles: ["PARTNER"],
      },
    ],
  },
  {
    label: "Broadcast",
    icon: "ri-shield-user-line",
    link: "/whatsapp-broadcast",
    roles: ["PARTNER"],
  },
  {
    label: "Word Cloud",
    icon: "ri-shield-user-line",
    roles: ["PARTNER"],
    subItems: [
      {
        label: "Rangkuman",
        icon: "ri-shield-user-line",
        link: "/whatsapp-group-word-search-summary",
        roles: ["PARTNER"],
      },
      {
        label: "Data Kata",
        icon: "ri-shield-user-line",
        link: "/whatsapp-group-word-search-summary-word",
        roles: ["PARTNER"],
      },
      {
        label: "Kategori Kata",
        icon: "ri-shield-user-line",
        link: "/whatsapp-group-word-search-summary-word-category",
        roles: ["PARTNER"],
      },
    ],
  },
  {
    label: "Import",
    icon: "ri-shield-user-line",
    roles: ["PARTNER"],
    subItems: [
      {
        label: "Import Data",
        icon: "ri-shield-user-line",
        link: "/whatsapp-group-import",
        roles: ["PARTNER"],
      },
      {
        label: "Import Data Pending",
        icon: "ri-shield-user-line",
        link: "/whatsapp-group-import-pending",
        roles: ["PARTNER"],
      },
      {
        label: "Import Data Kontak",
        icon: "ri-shield-user-line",
        link: "/whatsapp-group-import-contact",
        roles: ["PARTNER"],
      },
    ],
  },
  {
    label: "Bot Host",
    icon: "ri-shield-user-line",
    link: "/whatsapp-number-host",
    roles: ["PARTNER"],
  },
];
