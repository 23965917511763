import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "home" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/auth/login",
    name: "auth-login",
    component: () => import("../views/pages/account/auth-google"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "home" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/pages/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: "home" } : { ...routeFrom });
      },
    },
  },
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/index"),
  },

  // Start Member Route
  {
    path: "/member",
    name: "list-member",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/member/index"),
  },
  {
    path: "/member/create",
    name: "create-member",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/member/form"),
  },
  {
    path: "/member/:id/edit",
    name: "edit-member",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/member/form"),
  },
  {
    path: "/member/:id/detail",
    name: "detail-member",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/member/detail"),
  },
  // Finish Member Route

  // Start UTM Route
  {
    path: "/utm",
    name: "list-utm",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utm/index"),
  },
  {
    path: "/utm/create",
    name: "create-utm",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utm/form"),
  },
  {
    path: "/utm/:id/edit",
    name: "edit-utm",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/utm/form"),
  },
  // Finish UTM Route

  // Start UTM Route
  {
    path: "/role",
    name: "list-role",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/role/index"),
  },
  // Finish UTM Route

  // Start UTM Route
  {
    path: "/user-otp",
    name: "list-user-otp",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user-otp/index"),
  },
  // Finish UTM Route

  // Start Admin Route
  {
    path: "/admin",
    name: "list-admin",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/admin/index"),
  },
  {
    path: "/admin/create",
    name: "create-admin",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/admin/form"),
  },
  {
    path: "/admin/:id/edit",
    name: "edit-admin",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/admin/form"),
  },
  // Finish Admin Route

  // Start survey Route
  {
    path: "/survey",
    name: "list-survey",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/survey/index"),
  },
  {
    path: "/survey/create",
    name: "create-survey",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/survey/form"),
  },
  {
    path: "/survey/:id/edit",
    name: "edit-survey",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/survey/form"),
  },
  {
    path: "/survey/:id/answer",
    name: "answer-survey",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/survey/list-answer"),
  },
  {
    path: "/survey/:id/answer/:answerId",
    name: "detail-answer-survey",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/survey/detail-answer"),
  },
  // Finish survey Route

  // Start exam Route
  {
    path: "/exam",
    name: "list-exam",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/exam/index"),
  },
  {
    path: "/exam/create",
    name: "create-exam",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/exam/form"),
  },
  {
    path: "/exam/:id/edit",
    name: "edit-exam",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/exam/form"),
  },
  {
    path: "/exam/:id/answer",
    name: "answer-exam",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/exam/list-answer"),
  },
  {
    path: "/exam/:id/answer/:answerId",
    name: "detail-answer-exam",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/exam/detail-answer"),
  },
  // Finish exam Route

  // Start Category Route
  {
    path: "/category",
    name: "list-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/index"),
  },
  {
    path: "/category/create",
    name: "create-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/form"),
  },
  {
    path: "/category/:id/edit",
    name: "edit-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/category/form"),
  },
  // Finish Category Route

  // Start Onboarding Route
  {
    path: "/onboarding",
    name: "list-onboarding",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/onboarding/index"),
  },
  {
    path: "/onboarding/create",
    name: "create-onboarding",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/onboarding/form"),
  },
  {
    path: "/onboarding/:id/edit",
    name: "edit-onboarding",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/onboarding/form"),
  },
  // Finish Onboarding Route

  // Start Rating Option Route
  {
    path: "/rating-option",
    name: "list-rating-option",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/rating-option/index"),
  },
  {
    path: "/rating-option/create",
    name: "create-rating-option",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/rating-option/form"),
  },
  {
    path: "/rating-option/:id/edit",
    name: "edit-rating-option",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/rating-option/form"),
  },
  // Finish Rating Option Route

  // Start Certification Route
  {
    path: "/certification",
    name: "list-certification",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/certification/index"),
  },
  {
    path: "/certification/create",
    name: "create-certification",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/certification/form"),
  },
  {
    path: "/certification/:id/edit",
    name: "edit-certification",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/certification/form"),
  },
  // Finish Certification Route

  // Start Master Certification Route
  {
    path: "/certification-master",
    name: "list-certification-master",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/certification-master/index"),
  },
  {
    path: "/certification-master/create",
    name: "create-certification-master",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/certification-master/form"),
  },
  {
    path: "/certification-master/:id/edit",
    name: "edit-certification-master",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/certification-master/form"),
  },
  // Finish Master Certification Route

  // Start Certification Event Route
  {
    path: "/certification-event",
    name: "list-certification-event",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/certification-event/index"),
  },
  // Finish Certification Event Route

  // Start Setting Route
  {
    path: "/setting",
    name: "setting",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/setting/index"),
  },
  // Finish Setting Route

  // Start banner Route
  {
    path: "/banner",
    name: "list-banner",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/banner/index"),
  },
  {
    path: "/banner/create",
    name: "create-banner",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/banner/form"),
  },
  {
    path: "/banner/:id/edit",
    name: "edit-banner",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/banner/form"),
  },
  // Finish banner Route

  // Start Tag Route
  {
    path: "/tag",
    name: "list-tag",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/tag/index"),
  },
  {
    path: "/tag/create",
    name: "create-tag",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/tag/form"),
  },
  {
    path: "/tag/:id/edit",
    name: "edit-tag",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/tag/form"),
  },
  // Finish Tag Route

  // Start Forum Route
  {
    path: "/forum",
    name: "list-forum",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum/index"),
  },
  {
    path: "/forum/create",
    name: "create-forum",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum/form"),
  },
  {
    path: "/forum/:id/edit",
    name: "edit-forum",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum/form"),
  },
  {
    path: "/forum/:id/detail",
    name: "detail-forum",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum/detail"),
  },
  // Finish Forum Route

  // Start Forum Route
  {
    path: "/forum-group",
    name: "list-forum-group",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-group/index"),
  },
  {
    path: "/forum-group/create",
    name: "create-forum-group",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-group/form"),
  },
  {
    path: "/forum-group/:id/edit",
    name: "edit-forum-group",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-group/form"),
  },
  // Finish Forum Route

  // Start Notification Blast Route
  {
    path: "/notification-blast",
    name: "list-notification-blast",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/notification-blast/index"),
  },
  {
    path: "/notification-blast/create",
    name: "create-notification-blast",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/notification-blast/form"),
  },
  // Finish Notification Blast Route

  // Start User Route
  {
    path: "/user-group",
    name: "list-user-group",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user-group/index"),
  },
  {
    path: "/user-group/create",
    name: "create-user-group",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user-group/form"),
  },
  {
    path: "/user-group/:id/edit",
    name: "edit-user-group",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user-group/form"),
  },
  // Finish User Route

  // Start Whatsapp Number Host Route
  {
    path: "/whatsapp-number-host",
    name: "list-whatsapp-number-host",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-number-host/index"),
  },
  {
    path: "/whatsapp-number-host/create",
    name: "create-whatsapp-number-host",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-number-host/form"),
  },
  {
    path: "/whatsapp-number-host/:id/edit",
    name: "edit-whatsapp-number-host",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-number-host/form"),
  },
  // Finish Whatsapp Number Host Route

  // Start Whatsapp Group Greeting Template Route
  {
    path: "/whatsapp-group-greeting-template",
    name: "list-whatsapp-group-greeting-template",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-greeting-template/index"),
  },
  {
    path: "/whatsapp-group-greeting-template/create",
    name: "create-whatsapp-group-greeting-template",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-greeting-template/form"),
  },
  {
    path: "/whatsapp-group-greeting-template/:id/edit",
    name: "edit-whatsapp-group-greeting-template",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-greeting-template/form"),
  },
  // Finish Whatsapp Group Greeting Template Route

  // Start Whatsapp Group Greeting Template Route
  {
    path: "/whatsapp-group-greeting-history",
    name: "list-whatsapp-group-greeting-history",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-greeting-history/index"),
  },
  // Finish Whatsapp Group Greeting Template Route

  // Start Whatsapp Group Invitation History Route
  {
    path: "/whatsapp-group-invitation-history",
    name: "list-whatsapp-group-invitation-history",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-invitation-history/index"),
  },
  // Finish Whatsapp Group Invitation History Route

  // Start Whatsapp Group Route
  {
    path: "/whatsapp-group",
    name: "list-whatsapp-group",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-group/index"),
  },
  {
    path: "/whatsapp-group-invite",
    name: "list-whatsapp-group-invite",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-group/invite"),
  },
  {
    path: "/whatsapp-group/resume-activity",
    name: "resume-activity-whatsapp-group",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-group/resume"),
  },
  {
    path: "/whatsapp-group/create",
    name: "create-whatsapp-group",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-group/form"),
  },
  {
    path: "/whatsapp-group/:id/edit",
    name: "edit-whatsapp-group",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-group/form"),
  },
  // Finish Whatsapp Group Route

  // Start Whatsapp Group Route
  {
    path: "/whatsapp-group-onboarding-filter",
    name: "list-whatsapp-group-onboarding-filter",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-onboarding-filter/index"),
  },
  {
    path: "/whatsapp-group-onboarding-filter/create",
    name: "create-whatsapp-group-onboarding-filter",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-onboarding-filter/form"),
  },
  {
    path: "/whatsapp-group-onboarding-filter/:id/edit",
    name: "edit-whatsapp-group-onboarding-filter",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-onboarding-filter/form"),
  },
  // Finish Whatsapp Group Route

  // Start Whatsapp Blaster Route
  {
    path: "/whatsapp-blaster",
    name: "list-whatsapp-blaster",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-blaster/index"),
  },
  {
    path: "/whatsapp-blaster/:id/queue",
    name: "queue-whatsapp-blaster",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-blaster/detail"),
  },
  {
    path: "/whatsapp-blaster/create",
    name: "create-whatsapp-blaster",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-blaster/form"),
  },
  {
    path: "/whatsapp-blaster/:id/edit",
    name: "edit-whatsapp-blaster",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-blaster/form"),
  },

  // Start Whatsapp Broadcast Route
  {
    path: "/whatsapp-broadcast",
    name: "list-whatsapp-broadcast",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-broadcast/index"),
  },
  {
    path: "/whatsapp-broadcast/create",
    name: "create-whatsapp-broadcast",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-broadcast/form"),
  },
  {
    path: "/whatsapp-broadcast/:id/edit",
    name: "edit-whatsapp-broadcast",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-broadcast/form"),
  },

  // Start Whatsapp Broadcast Route
  {
    path: "/whatsapp-group-word-search-summary",
    name: "list-whatsapp-group-word-search-summary",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-word-search-summary/index"),
  },
  {
    path: "/whatsapp-group-word-search-summary-word",
    name: "list-whatsapp-group-word-search-summary-word",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-word-search-summary-word/index"),
  },
  {
    path: "/whatsapp-group-word-search-summary-word/create",
    name: "create-whatsapp-group-word-search-summary-word",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-word-search-summary-word/form"),
  },
  {
    path: "/whatsapp-group-word-search-summary-word/:id/edit",
    name: "edit-whatsapp-group-word-search-summary-word",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-word-search-summary-word/form"),
  },
  {
    path: "/whatsapp-group-word-search-summary-word-category",
    name: "list-whatsapp-group-word-search-summary-word-category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/whatsapp-group-word-search-summary-word-category/index"
      ),
  },
  {
    path: "/whatsapp-group-word-search-summary-word-category/create",
    name: "create-whatsapp-group-word-search-summary-word-category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/whatsapp-group-word-search-summary-word-category/form"
      ),
  },
  {
    path: "/whatsapp-group-word-search-summary-word-category/:id/edit",
    name: "edit-whatsapp-group-word-search-summary-word-category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "../views/pages/whatsapp-group-word-search-summary-word-category/form"
      ),
  },

  // Whatsapp Group Leave Survey Question
  {
    path: "/whatsapp-group-leave-survey-question",
    name: "whatsapp-group-leave-survey-question",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-leave-survey/question"),
  },

  // Whatsapp Group Leave Survey
  {
    path: "/whatsapp-group-leave-survey",
    name: "whatsapp-group-leave-survey",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-group-leave-survey/index"),
  },
  {
    path: "/whatsapp-group-leave-survey/:id/detail",
    name: "whatsapp-group-leave-survey-detail",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-leave-survey/detail"),
  },

  // Start Whatsapp Group Kulwa
  {
    path: "/whatsapp-group-kulwa",
    name: "list-whatsapp-group-kulwa",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-group-kulwa/index"),
  },
  {
    path: "/whatsapp-group-kulwa/create",
    name: "create-whatsapp-group-kulwa",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-group-kulwa/form"),
  },
  {
    path: "/whatsapp-group-kulwa/:id/edit",
    name: "edit-whatsapp-group-kulwa",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-group-kulwa/form"),
  },

  {
    path: "/whatsapp-group-kulwa-template",
    name: "list-whatsapp-group-kulwa-template",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-kulwa-template/index"),
  },
  {
    path: "/whatsapp-group-kulwa-template/create",
    name: "create-whatsapp-group-kulwa-template",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-kulwa-template/form"),
  },
  {
    path: "/whatsapp-group-kulwa-template/:id/edit",
    name: "edit-whatsapp-group-kulwa-template",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-kulwa-template/form"),
  },

  // Start Whatsapp Group Import
  {
    path: "/whatsapp-group-import",
    name: "list-whatsapp-group-import",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/whatsapp-group-import/index"),
  },
  {
    path: "/whatsapp-group-import-pending",
    name: "list-whatsapp-group-import-pending",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-import-pending/index"),
  },
  {
    path: "/whatsapp-group-import-contact",
    name: "list-whatsapp-group-import-contact",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-group-import-contact/index"),
  },
  // {
  //   path: "/whatsapp-blaster/filter-template",
  //   name: "whatsapp-blaster-template-form",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/whatsapp-blaster/template-form"),
  // },
  {
    path: "/whatsapp-blaster-filter-template",
    name: "whatsapp-blaster-filter-template",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/whatsapp-blaster-filter-template/index"),
  },
  // Finish Whatsapp Blaster Route

  // Start Forum Route
  {
    path: "/forum-partner",
    name: "list-forum-group-partner",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-group-partner/index"),
  },
  {
    path: "/forum-partner/create",
    name: "create-forum-group-partner",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-group-partner/form"),
  },
  {
    path: "/forum-partner/:id/edit",
    name: "edit-forum-group-partner",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-group-partner/form"),
  },
  // Finish Forum Route

  // Start Forum Regional Route
  {
    path: "/forum/regional",
    name: "list-forum-regional",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-regional/index"),
  },
  {
    path: "/forum/regional/create",
    name: "create-forum-regional",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-regional/form"),
  },
  {
    path: "/forum/regional/:id/edit",
    name: "edit-forum-regional",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-regional/form"),
  },
  // Finish Forum Regional Route

  // Start Notification Tempalte Route
  {
    path: "/notification-template",
    name: "list-notification-template",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/notification-template/index"),
  },
  {
    path: "/notification-template/:id",
    name: "edit-notification-template",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/notification-template/form"),
  },
  // Finish Notification Tempalte Route

  // Start Question Course Route
  {
    path: "/course/question",
    name: "list-course-question",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/course-question/index"),
  },
  {
    path: "/course/question/:id/answer",
    name: "edit-course-question",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/course-question/form"),
  },
  // Finish Question Course Route

  // Start Forum Topic Route
  {
    path: "/forum/topic",
    name: "list-forum-topic",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-topic/index"),
  },
  {
    path: "/forum/topic/create",
    name: "create-forum-topic",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-topic/form"),
  },
  {
    path: "/forum/topic/:id/edit",
    name: "edit-forum-topic",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-topic/form"),
  },
  // Finish Forum Topic Route

  // Start Forum Topic Route
  {
    path: "/forum/report",
    name: "list-forum-report",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-report/index"),
  },
  {
    path: "/forum/report/create",
    name: "create-forum-report",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-report/form"),
  },
  {
    path: "/forum/report/:id/edit",
    name: "edit-forum-report",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/forum-report/form"),
  },
  // Finish Forum Topic Route

  // Start Order Proof Payment Route
  {
    path: "/order/proof-payment",
    name: "list-order-proof-payment",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/order-proof-payment/index"),
  },
  {
    path: "/order/proof-payment/:id",
    name: "detail-order-proof-payment",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/order-proof-payment/form"),
  },
  // Finish Order Proof Payment Route

  // Start User Category Route
  {
    path: "/user/category",
    name: "list-user-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user-category/index"),
  },
  {
    path: "/user/category/create",
    name: "create-user-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user-category/form"),
  },

  {
    path: "/user/category/:id/edit",
    name: "edit-user-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user-category/form"),
  },
  // Finish User Category Route

  // Start BadgeRoute
  {
    path: "/badge",
    name: "list-badge",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/badge/index"),
  },
  {
    path: "/badge/create",
    name: "create-badge",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/badge/form"),
  },

  {
    path: "/badge/:id/edit",
    name: "edit-badge",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/badge/form"),
  },
  // Finish BadgeRoute

  // Start Voucher Route
  {
    path: "/voucher",
    name: "list-voucher",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/voucher/index"),
  },
  {
    path: "/voucher/create",
    name: "create-voucher",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/voucher/form"),
  },
  {
    path: "/voucher/:id/edit",
    name: "edit-voucher",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/voucher/form"),
  },
  // Finish Voucher Route

  // Start Activation Class Route
  {
    path: "/activation-class",
    name: "list-activation-class",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/activation-class/index"),
  },
  {
    path: "/activation-class/create",
    name: "create-activation-class",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/activation-class/form"),
  },
  {
    path: "/activation-class/:id/edit",
    name: "edit-activation-class",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/activation-class/form"),
  },
  // Finish Activation Class Route

  // Start Submodule Route
  {
    path: "/submodule",
    name: "list-submodule",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/submodule/index"),
  },
  {
    path: "/submodule/create",
    name: "create-submodule",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/submodule/form"),
  },
  {
    path: "/submodule/:id/edit",
    name: "edit-submodule",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/submodule/form"),
  },
  // Finish Submodule Route

  // Start Log Route
  {
    path: "/log",
    name: "edit-submodule",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/log/index"),
  },
  {
    path: "/log-audit",
    name: "log-audit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/log-audit/index"),
  },
  // Finish Log Route

  // Start Bank QNA Route
  {
    path: "/bank-qna",
    name: "list-bank-qna",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/bank-qna/index"),
  },
  {
    path: "/bank-qna/create",
    name: "create-bank-qna",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/bank-qna/form"),
  },
  {
    path: "/bank-qna/:id/edit",
    name: "edit-bank-qna",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/bank-qna/form"),
  },
  // Finish Bank QNA Route

  // Start Course Route
  {
    path: "/course",
    name: "list-course",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/course/index"),
  },
  {
    path: "/course/create",
    name: "create-course",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/course/form"),
  },
  {
    path: "/course/:id/edit",
    name: "edit-course",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/course/form"),
  },
  // Finish Course Route

  // Start Course Rating Route
  {
    path: "/course-rating",
    name: "list-course-rating",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/course-rating/index"),
  },
  // Finish Course Rating Route

  // Start Module Route
  {
    path: "/module",
    name: "list-module",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/module/index"),
  },
  {
    path: "/module/create",
    name: "create-module",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/module/form"),
  },
  {
    path: "/module/:id/edit",
    name: "edit-module",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/module/form"),
  },
  // Finish Module Route

  // Start Giveaway Route
  {
    path: "/giveaway",
    name: "list-giveaway",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/giveaway/index"),
  },
  {
    path: "/giveaway/create",
    name: "create-giveaway",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/giveaway/form"),
  },
  {
    path: "/giveaway/:id/edit",
    name: "edit-giveaway",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/giveaway/form"),
  },
  {
    path: "/giveaway/:id/winner",
    name: "winner-giveaway",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/giveaway/winner"),
  },
  // Finish Giveaway Route

  // Start Giveaway Route
  {
    path: "/user-course-certificate",
    name: "list-user-course-certificate",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user-course-certificate/index"),
  },
  // Finish Giveaway Route

  // Start Batch Route
  {
    path: "/batch",
    name: "list-batch",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/batch/index"),
  },
  {
    path: "/batch/create",
    name: "create-batch",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/batch/form"),
  },
  {
    path: "/batch/:id/edit",
    name: "edit-batch",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/batch/form"),
  },
  // Finish Batch Route

  {
    path: "/chat",
    name: "Chat",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/chat/index"),
  },

  // Start Order Route
  {
    path: "/order",
    name: "list-order",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/order/index"),
  },
  {
    path: "/order/:id/detail",
    name: "detail-order",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/order/form"),
  },
  // Finish Order Route

  // Dashboard
  {
    path: "/dashboard/order",
    name: "dashboard-order",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/order"),
  },
  // Dashboard
  {
    path: "/dashboard/enrollment",
    name: "dashboard-enrollment",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/enrollment"),
  },

  // Partnership Partner Category Route
  {
    path: "/partnership/partner-category",
    name: "list-partnership-partner-category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/partnership-partner-category/index"),
  },
  {
    path: "/partnership/partner-category/create",
    name: "create-partnership-partner-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-partner-category/form"),
  },
  {
    path: "/partnership/partner-category/:id/edit",
    name: "edit-partnership-partner-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-partner-category/form"),
  },

  // Partnership Clinic Category Route
  {
    path: "/clinic-category",
    name: "list-clinic-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/clinic-category/index"),
  },
  {
    path: "/clinic-category/create",
    name: "create-clinic-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/clinic-category/form"),
  },
  {
    path: "/clinic-category/:id/edit",
    name: "edit-clinic-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/clinic-category/form"),
  },

  // Partnership Clinic Route
  {
    path: "/clinic",
    name: "list-clinic",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/clinic/index"),
  },
  {
    path: "/clinic/:id/detail",
    name: "detail-clinic",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/clinic/form"),
  },
  {
    path: "/clinic/dashboard",
    name: "dashboard-clinic",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/clinic/form"),
  },

  // Partnership Partner Product Category Route
  {
    path: "/partnership/partner/product/category",
    name: "list-partnership-partner-product-category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/partnership-partner-product-category/index"),
  },
  {
    path: "/partnership/partner/product/category/create",
    name: "create-partnership-partner-product-category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/partnership-partner-product-category/form"),
  },
  {
    path: "/partnership/partner/product/category/:id/edit",
    name: "edit-partnership-partner-product-category",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("../views/pages/partnership-partner-product-category/form"),
  },

  // Partnership Partner Product Route
  {
    path: "/partnership/partner/product",
    name: "list-partnership-partner-product",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-partner-product/index"),
  },
  {
    path: "/partnership/partner/product/create",
    name: "create-partnership-partner-product",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-partner-product/form"),
  },
  {
    path: "/partnership/partner/product/:id/edit",
    name: "edit-partnership-partner-product",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-partner-product/form"),
  },

  // Partnership Partner Type Route
  {
    path: "/partnership/partner-type",
    name: "list-partnership-partner-type",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-partner-type/index"),
  },
  {
    path: "/partnership/partner-type/create",
    name: "create-partnership-partner-type",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-partner-type/form"),
  },
  {
    path: "/partnership/partner-type/:id/edit",
    name: "edit-partnership-partner-type",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-partner-type/form"),
  },

  // Partnership Item Category Route
  {
    path: "/partnership/item-category",
    name: "list-partnership-item-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-item-category/index"),
  },
  {
    path: "/partnership/item-category/create",
    name: "create-partnership-item-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-item-category/form"),
  },
  {
    path: "/partnership/item-category/:id/edit",
    name: "edit-partnership-item-category",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-item-category/form"),
  },

  // Partnership Item Category Route
  {
    path: "/partnership/partner",
    name: "list-partnership-partner",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-partner/index"),
  },
  {
    path: "/partnership/partner/create",
    name: "create-partnership-partner",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-partner/form"),
  },
  {
    path: "/partnership/partner/:id/edit",
    name: "edit-partnership-partner",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-partner/form"),
  },

  // Partnership Advertisement Route
  {
    path: "/partnership/advertisement",
    name: "list-partnership-advertisement",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-advertisement/index"),
  },
  {
    path: "/partnership/advertisement/create",
    name: "create-partnership-advertisement",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-advertisement/form"),
  },
  {
    path: "/partnership/advertisement/:id/edit",
    name: "edit-partnership-advertisement",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-advertisement/form"),
  },

  // Partnership Item Route
  {
    path: "/partnership/item",
    name: "list-partnership-item",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-item/index"),
  },
  {
    path: "/partnership/item/create",
    name: "create-partnership-item",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-item/form"),
  },
  {
    path: "/partnership/item/:id/edit",
    name: "edit-partnership-item",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership-item/form"),
  },

  // Partnership Item Category Route
  {
    path: "/partnership",
    name: "list-partnership",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership/index"),
  },
  {
    path: "/partnership/create",
    name: "create-partnership",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership/form"),
  },
  {
    path: "/partnership/:id/edit",
    name: "edit-partnership",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/partnership/form"),
  },
];
