<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      otp: "",
      errors: {
        username: "",
      },
      showOtp: false,
      countdown: 300,
      timer: null,
    };
  },
  mounted() {
    this.loadAndInitializeGoogleApi();
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    displayTime() {
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    },
    canResendOtp() {
      return this.countdown === 0;
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
    this.startCountdown();
  },
  validations: {
    username: { required },
    password: { required },
    otp: { required },
  },
  methods: {
    ...mapActions("auth", {
      logIn: "logIn",
      verificationOtp: "verificationOtp",
      resendOtp: "resendOtp",
    }),

    loadAndInitializeGoogleApi() {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      document.head.appendChild(script);
    },
    initializeGoogleApiClient() {
      // eslint-disable-next-line no-undef
      gapi.load("client:auth2", () => {
        // eslint-disable-next-line no-undef
        gapi.client
          .init({
            apiKey: process.env.VUE_APP_GOOGLE_API_KEY, // Replace with your API key
            clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID, // Replace with your client ID
            discoveryDocs: [
              "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
            ],
            scope: "https://www.googleapis.com/auth/drive.metadata.readonly",
          })
          .then(() => {
            console.log("Google API client initialized.");
            // Now you can make API calls
          })
          .catch((error) => {
            console.error("Error initializing Google API client:", error);
          });
      });
    },

    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      this.errors = { username: "" };
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      const { username, password } = this;
      if (username && password) {
        const { success, errors } = await this.logIn({
          username,
          password,
        });

        // if (success) {
        //   if (roles.findIndex((role) => role.name === "PARTNER") > -1) {
        //     window.location = this.$router.resolve({
        //       name: "resume-activity-whatsapp-group",
        //     }).href;
        //   } else {
        //     window.location = "/";
        //   }
        // } else {
        //   this.errors = errors;
        // }
        console.log(success);

        if (success) {
          this.showOtp = true;
        } else {
          this.errors = errors;
        }
      }
    },

    async verifyOtp() {
      this.errors = { otp: "" };
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      const { otp } = this;
      if (otp) {
        const { success, errors } = await this.verificationOtp({
          username: this.username,
          password: this.password,
          code: otp,
        });

        if (success) {
          window.location = "/";
        } else {
          this.errors = errors;
        }
      }
    },

    startCountdown() {
      this.countdown = 300; // 5 minutes in seconds
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.timer);
      this.timer = null;
    },
    resendOtp() {
      if (this.canResendOtp) {
        // Call your resend OTP action
        this.startCountdown(); // Restart the countdown
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <img
                            src="@/assets/images/logo/logo-text.png"
                            height="100"
                            alt="logo"
                          />
                        </div>

                        <h4 class="font-size-18 mt-4">Selamat datang !</h4>
                        <p class="text-muted">
                          Masuk untuk melanjutkan ke bolu.
                        </p>
                      </div>

                      <b-alert
                        :variant="notification.type"
                        class="mt-3"
                        dismissible
                        @dismissed="$store.dispatch('notification/clear')"
                        v-if="notification.message"
                        show
                        >{{ notification.message }}</b-alert
                      >

                      <div class="p-2 mt-5">
                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToLogIn"
                          v-if="!showOtp"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="username">Username</label>
                            <input
                              type="text"
                              v-model="username"
                              class="form-control"
                              id="username"
                              placeholder="Masukan username"
                              :class="{
                                'is-invalid':
                                  (submitted && $v.username.$error) ||
                                  errors.username !== '',
                              }"
                            />
                            <div
                              v-if="errors.username !== ''"
                              class="invalid-feedback"
                            >
                              <span>{{ errors.username }}</span>
                            </div>
                            <div
                              v-if="submitted && $v.username.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.username.required"
                                >username is required.</span
                              >
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Password</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Masukan password"
                              :class="{
                                'is-invalid': submitted && $v.password.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Masuk
                            </button>
                          </div>

                          <p class="text-center mt-4"><b>Atau</b></p>

                          <div style="display: flex; justify-content: center;">
                            <div
                              id="g_id_onload"
                              data-client_id="331067673413-ccqf5fdbc64pg64u1v9iso8hli248vdr.apps.googleusercontent.com"
                              data-context="signin"
                              data-ux_mode="popup"
                              data-login_uri="https://api.bolu.id/v1/authentications/google-login"
                              data-nonce=""
                              data-itp_support="true"
                            ></div>

                            <div
                              class="g_id_signin"
                              data-type="standard"
                              data-shape="pill"
                              data-theme="filled_black"
                              data-text="signin_with"
                              data-size="large"
                              data-logo_alignment="left"
                              data-width="200"
                            ></div>
                          </div>
                        </form>

                        <form
                          class="form-horizontal"
                          @submit.prevent="verifyOtp"
                          v-if="showOtp"
                        >
                          <p class="text-center">
                            Masukan kode OTP yang telah dikirim ke email Anda.
                          </p>
                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="otp">OTP</label>
                            <input
                              type="text"
                              v-model="otp"
                              class="form-control"
                              id="otp"
                              placeholder="Masukan OTP"
                              :class="{
                                'is-invalid':
                                  (submitted && $v.otp.$error) ||
                                  errors.otp !== '',
                              }"
                            />
                            <div
                              v-if="errors.otp !== ''"
                              class="invalid-feedback"
                            >
                              <span>{{ errors.otp }}</span>
                            </div>
                            <div
                              v-if="submitted && $v.otp.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.otp.required"
                                >Otp is required.</span
                              >
                            </div>
                          </div>

                          <!-- Bagian untuk Counter Resend OTP -->
                          <div class="mt-4 text-center">
                            <p v-if="countdown > 0">
                              Kirim ulang OTP dalam {{ displayTime }}
                            </p>
                            <button
                              class="btn btn-primary"
                              @click="resendOtp"
                              :disabled="countdown > 0"
                              v-if="countdown === 0"
                            >
                              Kirim Ulang OTP
                            </button>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Verifikasi OTP
                            </button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          © 2021 Bolu
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
